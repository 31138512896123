import ImgSuccess from "../../assets/images/success.svg";
import { THEME_COLOR } from "./../../config/config";
import { getResellerInfo } from "../../config/api_calls";
import { useEffect, useState } from "react";
import { AGENT_PORTAL_URL } from "./../../config/config";

export default function Success() {
  const [dashboardUrl, setDashboardUrl] = useState(null);
  useEffect(() => {
    window.history.replaceState(null, "", window.location.href);

    const fetchData = async () => {
      try {
        const servicesResponse = await getResellerInfo(
          process.env.REACT_APP_API_TOKEN,
          process.env.REACT_APP_API_ACCOUNTNO
        );

        console.log("servicesResponse: ", servicesResponse.data);

        const { status, message, data } = servicesResponse.data;

        if (status === 200 && message === "success" && data?.reseller_identifier) {
          setDashboardUrl(`${AGENT_PORTAL_URL}${data.reseller_identifier}/client`);
        } else if (status === 403) {
          console.error("Authentication error or token expired.");
        } else if (status === 404) {
          console.warn("Data not found.");
        } else {
          console.warn("Unexpected response format.");
        }
      } catch (error) {
        console.error("Error fetching data:", error.message || error);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="nk-body bg-white npc-general pg-error">
      <div className="nk-app-root">
        <div className="nk-main ">
          <div className="nk-wrap justify-center">
            <div className="nk-content ">
              <div className="nk-block nk-block-middle wide-md mx-auto">
                <div className="nk-block-content nk-error-ld text-center">
                  <img className="nk-error-gfx" src={ImgSuccess} alt="" />
                  <div className="wide-xs mx-auto">
                    <h3 className="nk-error-title">Success</h3>
                    <p className="nk-error-text">
                    Thank you for submitting your form successfully. Please click the below button to go to your dashboard.
                    </p>
                    {dashboardUrl ? (
                      <a
                        href={dashboardUrl}
                        className="btn btn-lg mt-2"
                        style={{ backgroundColor: THEME_COLOR, color: "white" }}
                      >
                        Go to Dashboard
                      </a>
                    ) : (
                      <div class="spinner-border spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
