import React, { Component, Fragment } from "react";
import {
  AppBar,
  Button,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { LOGO_VERIFY } from "../../../config/config";

import HelperClass from "../../../config/helperClass";
import { tableLoader } from "../../ContentLoaders/table_loader";
import Skeleton from "@material-ui/lab/Skeleton";

import { Helmet, HelmetProvider } from "react-helmet-async";



import MyCustomTextField from "../../../components/InputFields/TextField";
import MyCustomRadioField from "../../../components/InputFields/RadioField";
import MyCustomCheckBoxField from "../../../components/InputFields/CheckBox";
import MyCustomButton from "../../../components/InputFields/Button";
import "../ClientRegister/registration_step.css";

import { registerOtherInformations } from "../../../config/client_api_calls";



const auth = new HelperClass();
export class DomainConnect extends Component {
  continue = (data) => {
    //e.preventDefault();
    this.props.nextStep(data);
  };
  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  submitForms = (e) => {
    e.preventDefault();
    const { jsonData } = this.props;
    const { keys } = this.props.values;

    let bank_details_json = {      
      "required": jsonData.step_7.sub_steps[0].input_fields.bank_details.required,
      "visible": jsonData.step_7.sub_steps[0].input_fields.bank_details.visible  
    };
    let bank_name_json = {      
      "required": jsonData.step_7.sub_steps[0].input_fields.bank_details.bank_name.required,
      "visible": jsonData.step_7.sub_steps[0].input_fields.bank_details.bank_name.visible  
    };
    let bank_routing_number_json = {      
      "required": jsonData.step_7.sub_steps[0].input_fields.bank_details.bank_routing_number.required,
      "visible": jsonData.step_7.sub_steps[0].input_fields.bank_details.bank_routing_number.visible  
    };
    let bank_account_number_json = {      
      "required": jsonData.step_7.sub_steps[0].input_fields.bank_details.bank_account_number.required,
      "visible": jsonData.step_7.sub_steps[0].input_fields.bank_details.bank_account_number.visible  
    };

    
    if (this.state[keys.us_bank_account]) {
      if(bank_details_json.visible && bank_details_json.required){
        if(bank_name_json.visible && bank_name_json.required && this.state[keys.bank_name] === ""){
          this.setState({
            errorMessage: "Please enter Bank Name",
          });
          return;
        }
        if(bank_routing_number_json.visible && bank_routing_number_json.required && this.state[keys.bank_routing_number] === ""){
          this.setState({
            errorMessage: "Please enter Bank Routing Number",
          });
          return;
        }
        if(bank_account_number_json.visible && bank_account_number_json.required && this.state[keys.bank_account_number] === ""){
          this.setState({
            errorMessage: "Please enter Bank Account Number",
          });
          return;
        }
        
      }
    }

    this.setState({ errorMessage: "", successMessage: "", disabled: true });
    const step7_json = {
      keys: keys,
      values: {
        [keys.bank_name]: this.state[keys.bank_name],
        [keys.bank_routing_number]: this.state[keys.bank_routing_number],
        [keys.bank_account_number]: this.state[keys.bank_account_number],
        [keys.us_bank_account]: this.state[keys.us_bank_account],
        [keys.customer_affirmation]: this.state[keys.customer_affirmation],
      },
    };
    console.log('step_7_data', step7_json);
    this.submitOtherInformations(step7_json);
    //this.continue(step7_json);
  };

  submitOtherInformations = async (otherInfo) => {
    const {
      signup_hash,
      steps_completed,
      steps_allowed
    } = this.props;

    //console.log('client_email',this.props);
    if (signup_hash !== "") {
      const {keys} = this.props.values;
      try {
        let response = await registerOtherInformations({          
          token: process.env.REACT_APP_API_TOKEN,
          api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          signup_hash: signup_hash,
          bank_name: otherInfo.values[keys.bank_name],
          bank_routing_number: otherInfo.values[keys.bank_routing_number],
          bank_account_number: otherInfo.values[keys.bank_account_number],
          us_bank_account: otherInfo.values[keys.us_bank_account],
        });
        console.log('submitOtherInformations',response);
        if (response.data.status === 404 ||
            response.data.status === 422
        ){
          this.setState({
            errorMessage: 'Something went wrong. Please try again',
            successMessage: "",
            disabled: false,            
          });          
        }
        else if(response.data.status === 200 &&
          response.data.message === "success"){
            if(steps_completed === (steps_allowed - 1)){
              // this.setState({
              //   errorMessage: '',
              //   successMessage: "Successfully submitted data.",
              //   disabled: true,            
              // });
              this.props.successHandler();
            }
            else{
              this.continue(otherInfo);
            }  
          }
      } catch (err) {
        this.setState({ errorMessage: "", successMessage: "", disabled: false });        
      }
    }

  };

  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      successMessage: "",
      disabled: props.jsonData.step_7.sub_steps[0].button_fields.continue.disabled,
    };
  }

  componentDidMount(){
    const {
      keys,
      values,
    } = this.props.values;

    let us_bank_account =  values[keys.us_bank_account] === "yes" ? true : values[keys.us_bank_account] === "no" ? false : values[keys.us_bank_account]

    this.setState({
      [keys.bank_name]:us_bank_account ? values[keys.bank_name] : "",
      [keys.bank_routing_number]: us_bank_account ? values[keys.bank_routing_number] : "",
      [keys.bank_account_number]:us_bank_account ? values[keys.bank_account_number] : "",
      [keys.us_bank_account]: us_bank_account,
      [keys.customer_affirmation]:values[keys.customer_affirmation],
    });
  }

  modalHideIns = () => {
    window.$("#instructionsModal").modal("hide");
  };

  handleChangeCheck = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
    console.log(name + " " + value);
  };

  render() {
    const { jsonData, mStepsUITitles, mStepsUIDesc, steps_completed, steps_allowed } = this.props;
    const {values, keys} = this.props.values;

    return (
      <>
        <HelmetProvider>
          <Helmet>
            <link
              rel="stylesheet"
              href="../../assets/ui_latest/css/dashlite.css?ver=3.0.3"
            />
            <link
              id="skin-default"
              rel="stylesheet"
              href="../../assets/ui_latest/csstheme.css?ver=3.0.3"
            />
          </Helmet>
        </HelmetProvider>
        <div className="nk-block nk-block-lg" style={{ width: "100%" }}>
          <div
            className="card card-bordered"
            style={{
              border: "none",
              paddingTop: "2%",
              paddingLeft: "5%",
              paddingRight: "5%",
            }}
          >
            <form
              className="nk-stepper stepper-init is-alter nk-stepper-s1"
              action="#"
              onSubmit={this.submitForms}
              id="stepper-create-profile"
            >
              <div className="row g-0 col-sep col-sep-md col-sep-xl">
                <div className="col-md-4 col-xl-4">
                  <div className="card-inner" id="registration_div">
                    <ul className="nk-stepper-nav nk-stepper-nav-s1 stepper-nav is-vr">
                    <li className={steps_completed === 0 ? "current" : "done"}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                              {mStepsUITitles[0]}
                            </div>
                            <div className="sub-text">
                              {mStepsUIDesc[0]}
                            </div>
                          </div>
                        </div>
                      </li>
                      {
                        steps_allowed >= 2 ? <li className={steps_completed === 1  ? "current" : steps_completed > 1 && steps_completed <= 3 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[1]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[1]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {
                        steps_allowed >= 3 ? <li className={steps_completed === 2  ? "current" : steps_completed > 2 && steps_completed <= 4 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[2]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[2]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {
                        steps_allowed >= 4 ? <li className={steps_completed === 3  ? "current" : steps_completed > 3 && steps_completed <= 5 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[3]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[3]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {
                        steps_allowed >= 5 ? <li className={steps_completed === 4  ? "current" : steps_completed > 4 && steps_completed <= 6 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[4]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[4]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {
                        steps_allowed >= 6 ? <li className={steps_completed === 5  ? "current" : steps_completed > 5 && steps_completed <= 7 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[5]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[5]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {
                        steps_allowed >= 7 ? <li className={steps_completed === 6  ? "current" : steps_completed > 6 && steps_completed <= 8 ? "done" : ""}>
                        <div className="step-item">
                          <div className="step-text">
                            <div className="lead-text">
                            {mStepsUITitles[6]}
                            </div>
                            <div className="sub-text">
                            {mStepsUIDesc[6]}
                            </div>
                          </div>
                        </div>
                      </li> : null
                      }
                      {steps_allowed >= 8 ? (
                        <li
                          className={
                            steps_completed === 7
                              ? "current"
                              : steps_completed > 7 && steps_completed <= 9
                              ? "done"
                              : ""
                          }
                        >
                          <div className="step-item">
                            <div className="step-text">
                              <div className="lead-text">
                                {mStepsUITitles[7]}
                              </div>
                              <div className="sub-text">{mStepsUIDesc[7]}</div>
                            </div>
                          </div>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </div>
                <div className="col-md-8 col-xl-8">
                  <div className="card-inner">
                    <div className="nk-stepper-content">
                      <div className="nk-stepper-steps stepper-steps">
                        {this.state.errorMessage !== "" ? (
                          <div
                            className="example-alert"
                            style={{ marginBottom: "20px" }}
                          >
                            <div className="alert alert-pro alert-danger">
                              <div className="alert-text">
                                <h4>Error</h4>
                                <p>{this.state.errorMessage}</p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {this.state.successMessage !== "" ? (
                          <div className="example-alert">
                            <div className="alert alert-pro alert-success">
                              <div className="alert-text">
                                <h4>Success</h4>
                                <p>{this.state.successMessage}</p>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="nk-stepper-step">
                          <h4
                            className="title mb-3"
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {jsonData.step_7.sub_steps[0].title}
                          </h4>
                          <p
                            style={{
                              textAlign: "center",
                              marginLeft: "10%",
                              marginRight: "10%",
                            }}
                          >
                            {jsonData.step_7.sub_steps[0].description}
                          </p>
                          <div className="row g-3">
                            {jsonData.step_7.sub_steps[0].checkbox_fields
                              .us_bank_account.visible ? (
                              <div className="col-12">
                                <div className="form-group">
                                  <ul className="custom-control-group custom-control-horizontal custom-control-stacked w-100">
                                    {jsonData.step_7.sub_steps[0].checkbox_fields.us_bank_account.data.map(
                                      (result, index) => (
                                        <li key={`us_bank_account${index}`}>
                                          <MyCustomCheckBoxField
                                            data={result}
                                            onChange={this.handleChangeCheck}
                                            checked={
                                              this.state[keys.us_bank_account]
                                            }
                                          />
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            ) : null}
                            {jsonData.step_7.sub_steps[0].input_fields
                              .bank_details.visible &&
                            this.state[keys.us_bank_account] === true ? (
                              <>
                                <h4
                                  className="title mb-3"
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {
                                    jsonData.step_7.sub_steps[0].input_fields
                                      .bank_details.label
                                  }
                                </h4>
                                <div className="col-lg-6">
                                  <MyCustomTextField
                                    data={
                                      jsonData.step_7.sub_steps[0].input_fields
                                        .bank_details.bank_name
                                    }
                                    onChange={this.handleChangeCheck}
                                    defaultValue={`${values[keys.bank_name]}`}
                                  />
                                </div>
                                <div className="col-lg-6">
                                  <MyCustomTextField
                                    data={
                                      jsonData.step_7.sub_steps[0].input_fields
                                        .bank_details.bank_routing_number
                                    }
                                    onChange={this.handleChangeCheck}
                                    defaultValue={`${
                                      values[keys.bank_routing_number]
                                    }`}
                                  />
                                </div>
                                <div className="col-12">
                                  <MyCustomTextField
                                    data={
                                      jsonData.step_7.sub_steps[0].input_fields
                                        .bank_details.bank_account_number
                                    }
                                    onChange={this.handleChangeCheck}
                                    defaultValue={`${
                                      values[keys.bank_account_number]
                                    }`}
                                  />
                                </div>
                              </>
                            ) : null}
                            {jsonData.step_7.sub_steps[0].checkbox_fields
                              .customer_affirmation.visible ? (
                              <div className="col-12">
                                <div className="form-group">
                                  <label className="form-label">
                                    {
                                      jsonData.step_7.sub_steps[0]
                                        .checkbox_fields.customer_affirmation
                                        .label
                                    }
                                    {jsonData.step_7.sub_steps[0]
                                      .checkbox_fields.customer_affirmation
                                      .required ? (
                                      <sup style={{ color: "red" }}>*</sup>
                                    ) : null}
                                  </label>

                                  <ul className="custom-control-group custom-control-horizontal custom-control-stacked w-100">
                                    {jsonData.step_7.sub_steps[0].checkbox_fields.customer_affirmation.data.map(
                                      (result, index) => (
                                        <li
                                          key={`customer_affirmation${index}`}
                                        >
                                          <MyCustomCheckBoxField
                                            data={result}
                                            onChange={this.handleChangeCheck}
                                          />
                                          <span className="ms-2" />
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step"></div>
                        <div className="nk-stepper-step text-center"></div>
                      </div>
                      <div className="row mt-5 gx-1 gy-1">
                      {jsonData.step_7.sub_steps[0].button_fields.back
                          .visible && steps_completed > 0 ? (
                          <div className={"col-lg-6"}>
                            <MyCustomButton
                              data={
                                jsonData.step_7.sub_steps[0].button_fields.back
                              }
                              onClick={this.back}
                            />
                          </div>
                        ) : null}
                        {jsonData.step_7.sub_steps[0].button_fields.continue
                          .visible && steps_completed < steps_allowed - 1 ? (
                            <div className={steps_completed === 0 ? "col-lg-12" : "col-lg-6"}>
                            <MyCustomButton
                              data={
                                jsonData.step_7.sub_steps[0].button_fields
                                  .continue
                              }
                              //onClick={this.submitForms}
                              disabled={this.state.disabled}
                            />
                          </div>
                        ) : null}
                        {jsonData.step_7.sub_steps[0].button_fields.submit
                          .visible && steps_completed === (steps_allowed - 1)  ? (
                          <div className="col-lg-6">
                            <MyCustomButton
                              data={
                                jsonData.step_7.sub_steps[0].button_fields
                                  .submit
                              }
                              //onClick={this.submitForms}
                              disabled={this.state.disabled}
                            />
                          </div>
                        ) : null}
                      </div>
                      {/* <div
                        className="text-center"
                        style={{ marginTop: "25px" }}
                      >
                        Already have an account ? <a href="">Sign in instead</a>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default DomainConnect;
